<style lang="less" scoped>
  .choose-worker {
    max-height: 350px;
    display: flex;
    flex-direction: column;
  }
</style>

<template>
  <div class="choose-worker">
    <fm-input-new placeholder="请输入姓名搜索" v-model="searchKey"></fm-input-new>
    <div style="flex: 1;overflow: auto;">
      <template v-if="multiple">
        <fm-checkbox-group vertical v-model="data" @change="$emit('input', data)">
          <fm-checkbox :label="item.key" :key="item.key" :value="item.key" v-for="item in workerList">{{item.label}}</fm-checkbox>
        </fm-checkbox-group>
      </template>
      <template v-else>
        <fm-radio-group style="margin-top: 10px;" vertical v-model="data" @change="$emit('input', data)">
          <fm-radio :label="String(item.label)" :key="item.key" :value="item.key" v-for="item in workerList"></fm-radio>
        </fm-radio-group>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [Array, Number, String], default: () => null },
    multiple: { type: Boolean, default: true }
  },
  data () {
    return {
      searchKey: null,
      data: this.value
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.data = this.value
      }
    }
  },
  computed: {
    workerList () {
      if (this.searchKey) {
        return this.$store.getters.workerList.filter(v => v.label.indexOf(this.searchKey) > -1)
      } else {
        return this.$store.getters.workerList
      }
    }
  }
}
</script>
